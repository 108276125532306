/* eslint-disable */
/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function() {
  return this.each(function(_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({ appearance: 'none', '::-ms-expand': 'none' });
    $icon.css({ pointerEvents: 'none' });
  })
};

(function toggleListInit() {
  if (!document.querySelector('[data-page="frequently_asked_questions"]') && !document.querySelector('[data-page="money_back_guarantee"]')) return false;

  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);
})();


(function (window, document) {
  function init() {
    var element = document.getElementById('CommonPage');

    if (element) {
      var pageName = element.getAttribute('data-page');
      var siteId = element.getAttribute('data-site-id');
      var policies = element.getAttribute('data-policies');
      var policyId = element.getAttribute('data-policy-id');

      let widgetOptions = {
        siteId,
        pageName,
      };

      if (policies && policyId) {
        widgetOptions = { siteId, pageName, policies, policyId };
      }

      window.commonPagesWidget.widget(element, widgetOptions);
    }
  }

  if (typeof window.commonPagesWidget !== 'undefined') {
    init();
  } else {
    var script = document.createElement('script');
    script.src = '/assets/js/commonPagesWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

/* ==========================================================================
   3. Custom JS for PaperWritingHelp.net
   ========================================================================== */

$(function(){

/*
   3.2. Add class "selected" for item select
   ========================================================================== */
    if ($.fn.uvoAutocomplete) {
        $('.place-your-order select').uvoAutocomplete({
            sortBy: 'none',
            onOpen: function (e) {
                var lebelEl = this.label;
                $(this._listElement.children()).each(function () {
                    if ($(this).text() == lebelEl) {
                        $(this).addClass('selected')
                    }
                });
            }
        });
    }

/*
   3.3. Count pages
   ========================================================================== */

$.fn.counts = function(options) {
		options = $.extend({
			minusClass: 'pageskol-left',
			minimum: 1
		}, options);
		var inputCol = $(this);
		if ($(inputCol).val() <= options.minimum) {
			$('button[class=' + options.minusClass + ']').css({
				'opacity': '0.5',
				'cursor': 'default'
			});
		} else {
			$('button[class=' + options.minusClass + ']').css({
				'opacity': '1',
				'cursor': 'pointer'
			});
		}
		$(inputCol).parent().find('button').click(function() {
			if ($(this).hasClass(options.minusClass)) {
				if ($(inputCol).val() >= options.minimum) {
					var count = parseInt($(inputCol).val()) - 1;
					count = count < options.minimum ? options.minimum : count;
				} else {
					$(inputCol).val(options.minimum - 1);
					var count = parseInt($(inputCol).val()) - 1;
					count = count < options.minimum ? options.minimum : count;
				}
				if ($(inputCol).val() <= (options.minimum + 1)) {
					$('button[class=' + options.minusClass + ']').css({
						'opacity': '0.5',
						'cursor': 'default'
					});
				}
			} else {
				if ($(inputCol).val() >= options.minimum) {
					var count = parseInt($(inputCol).val()) + 1;
				} else {
					$(inputCol).val(options.minimum - 1);
					var count = parseInt($(inputCol).val()) + 1;
				}
				if ($(inputCol).val() > (options.minimum - 1)) {
					$('button[class=' + options.minusClass + ']').css({
						'opacity': '1',
						'cursor': 'pointer'
					})
				}
			}
			$(inputCol).val(count);
			$(inputCol).change();
			$('.pageskol-title').find('button').html(275 * count);
			return false;
		});
		$(this).change(function(e) {
			var count = parseInt($(this).val())
			if (isNaN(count)) {
				count = 1;
				$(this).val(1);
			}
			$('.pageskol-title').find('span').html(275 * count);
		});
		$(this).focusout(function(e) {
			var count = parseInt($(this).val())
			if (isNaN(count)) {
				count = 1;
				$(this).val(1);
			}
			$('.pageskol-title').find('span').html(275 * count);
		});
		$(this).keydown(function(e) {
			if (e.which != 8 && e.which != 0 && (e.which < 37 || e.which > 57) && e.which !=
				96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which != 100 &&
				e.which != 101 && e.which != 102 && e.which != 103 && e.which != 104 &&
				e.which != 105) {
				return false;
			}
			var key, keyChar;
			if (!e) var e = window.e;
			if (e.keyCode) key = e.keyCode;
			else if (e.which) key = e.which;
			if (key == 8) {
				return true;
			}
			if (key == 38 || key == 39 || (e.which < 48 || e.which > 57) && e.which !=
				96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which != 100 &&
				e.which != 101 && e.which != 102 && e.which != 103 && e.which != 104 &&
				e.which != 105) {
				if (key == 40 || key == 37) {
					if ($(this).val() >= options.minimum) {
						var count = parseInt($(this).val()) - 1;
						count = count < options.minimum ? options.minimum : count;
					} else {
						$(this).val(options.minimum - 1);
						keyChar = String.fromCharCode(key);
						var count = parseInt($(this).val()) + 1;
					}
				} else {
					if ($(this).val() >= options.minimum) {
						var count = parseInt($(this).val()) + 1;
					} else {
						$(this).val(options.minimum - 1);
						var count = parseInt($(this).val()) + 1;
					}
				}
				$(this).val(count);
				$('.pageskol-title').find('span').html(275 * count);
				if ($(inputCol).val() <= (options.minimum)) {
					$('button[class=' + options.minusClass + ']').css({
						'opacity': '0.5',
						'cursor': 'default'
					});
				} else {
					$('button[class=' + options.minusClass + ']').css({
						'opacity': '1',
						'cursor': 'pointer'
					})
				}
			}
		});
	}

	$('#input-kol').counts();

/*
   3.4. To-top link click
   ========================================================================== */

	$('.totop-link').click(function() {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

/*
   3.5. Toggle Topmenu
   ========================================================================== */

	$('.toggle-menu').click(function() {
		$(this).toggleClass('active');
    $('html, body').addClass('no-scroll');

		if ($(this).next().css('display') == 'none') {
			$(this).next().show();

      $('.topmenu-block').css('height', window.innerHeight - 60 + 'px');
      $( window ).resize(function() {
        $('.topmenu-block').css('height', window.innerHeight - 60 + 'px');
      });

      var overlay = document.querySelector('.topmenu-block');
      var clientY = null;
      overlay.addEventListener('touchstart', function (event) {
        if (event.targetTouches.length === 1) {
          clientY = event.targetTouches[0].clientY;
        }
      }, false);
      overlay.addEventListener('touchmove', function (event) {
        if (event.targetTouches.length === 1) {
          disableRubberBand(event);
        }
      }, false);
      function disableRubberBand(event) {
        var _clientY = event.targetTouches[0].clientY - clientY;
        if (overlay.scrollTop === 0 && _clientY > 0) {
          event.preventDefault();
        }
        if (isOverlayTotallyScrolled() && _clientY < 0) {
          event.preventDefault();
        }
      }
      function isOverlayTotallyScrolled() {
        return overlay.scrollHeight - overlay.scrollTop <= overlay.clientHeight;
      }

		} else {
			$(this).next().hide();
		}
		return false;
	});
	if ($('.topmenu-line').length > 0) {
		var tmenu = $('.topmenu-line'),
			tmenuTop = $('.topmenu-line').offset().top;
	}

	(function() {
  	"use strict";
		var toggles = document.querySelectorAll(".c-hamburger");

		for (var i = toggles.length - 1; i >= 0; i--) {
			var toggle = toggles[i];
			toggleHandler(toggle);
		};

		function toggleHandler(toggle) {
			toggle.addEventListener( "click", function(e) {
				e.preventDefault();
				(this.classList.contains("is-active") === true) ? this.classList.remove("is-active") : this.classList.add("is-active");
			});
		}
})();

/*
   3.6. Fixed menu + Show To-top link
   ========================================================================== */

		var navHeight = jQuery('.topmenu-line').height(),
			//windowWidth = jQuery(window).outerWidth();
			windowWidth = parseInt(jQuery(window).width()) + 17,
      body = $("body");
			// console.log(windowWidth);

		jQuery(window).resize(function () {
			if (windowWidth >= 1025) {
				var navHeight = jQuery('.topmenu-line').height();
				if (jQuery(window).scrollTop() > navHeight) {
					jQuery('.topmenu-line').addClass("fixed");
          body.addClass("header-fixed__enabled");
				} else {
					jQuery('.topmenu-line').removeClass("fixed");
          body.removeClass("header-fixed__enabled");
				}
			}
		});

		jQuery(window).scroll(function () {
			if (windowWidth >= 1025) {
				if (jQuery(window).scrollTop() > navHeight) {
					jQuery('.topmenu-line').addClass("fixed");
          body.addClass("header-fixed__enabled");
				} else {
					jQuery('.topmenu-line').removeClass("fixed");
          body.removeClass("header-fixed__enabled");
				}
			} else {
        jQuery('.topmenu-line').removeClass("fixed");
        body.removeClass("header-fixed__enabled");
      }

			if ($(window).scrollTop() >= 200) {
			$('.totop-link').fadeIn();
			} else {
				$('.totop-link').fadeOut();
			}
		});
/*
   3.8. For Inquiry page
   ========================================================================== */

	$('.show-hide').find('.hidden').hide();
	$('#inq-done-more').click(function() {
		if (!$(this).hasClass('less')) {
			$(this).parents('.show-hide').find('.hidden').slideDown(500);
			$(this).text('Read less').addClass('less');
		} else {
			$(this).parents('.show-hide').find('.hidden').slideUp(500);
			$(this).text('Read more').removeClass('less');
		}
	});

/*
   3.9. Footer Site Links
   ========================================================================== */

	$('.footer-datalink').each(function(index) {
		var linkcurrent = $(this).attr('data-href');
		$(this).removeAttr('data-href');
		$(this).wrapInner("<a href='" + linkcurrent + "' rel='nofollow'></a>")
	});

/*
   3.10. Calculate title
   ========================================================================== */

	$(window).on('load', function() {
		$('.calculateBlock > h2.title').html(function() {
			var htmlString = $(this).html();
			$(this).text('Prices for Essays');
		});
	});

/*
   3.11. Price table cell hover td
   ========================================================================== */

	$('.writing-table tbody td:not(:first-child)').mouseenter(function() {
		var tr = $(this).parent('.writing-table tbody tr');
		var Col = tr.find('td').index(this);
		var Span = $('.writing-table tbody tr').index(tr.get(0));
		tr.find('td:lt(' + Col + ')').addClass('hover');
		$('.writing-table tbody tr:lt(' + Span + ')').find('td:eq(' + Col + ')').addClass(
			'hover');
		$(this).addClass('hover-cell');

	}).mouseleave(function() {
		$('.writing-table tbody td').removeClass('hover-cell hover');
	});

	$('.price_table_default tbody td:not(:first-child)').mouseenter(function() {
		var tr = $(this).parent('.price_table_default tbody tr');
		var Col = tr.find('td').index(this);
		var Span = $('.price_table_default tbody tr').index(tr.get(0));
		tr.find('td:lt(' + Col + ')').addClass('hover');
		$('.price_table_default tbody tr:lt(' + Span + ')').find('td:eq(' + Col +
			')').addClass('hover');
		$(this).addClass('hover-cell');

	}).mouseleave(function() {
		$('.price_table_default tbody td').removeClass('hover-cell hover');
	});
	$('.price_table_default tbody th').mouseenter(function() {
		var tr = $(this).parent('.price_table_default tbody tr');
		var Col = tr.find('th').index(this);
		var Span = $('.price_table_default tbody tr').index(tr.get(0));
		tr.find('th:lt(' + Col + ')').addClass('hover');
		$('.price_table_default tbody tr:lt(' + Span + ')').find('th:eq(' + Col +
			')').addClass('hover');
		$(this).addClass('hover-cell');

	}).mouseleave(function() {
		$('.price_table_default tbody th').removeClass('hover-cell hover');
	});

});

$(function() {
/*
   3.11. Bx Slider
   ========================================================================== */

    $("#testimonials-wrap").find('.bxslider').bxSlider({
        mode:"fade",
        speed: 750,
        auto: true,
        controls: false,
        autoControls: false
    });

/*
   3.11. Seo pages menu script (???)
   ========================================================================== */

    $("#menu").on("click","a", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = parseInt($(id).offset().top - 70);
        $('body,html').animate({scrollTop: top}, 500);
    });

});


/* =============================
*  NEW CALC INIT
================================ */
$.getScript('/assets/js/calc.js', function () {
	if ($('[data-calc-short]').length > 0) {
		new Calc({
			ID: '115',
			calculatorType: 'ShortCalculator',
			format: 'json',
			environment: getEnvironment,
			options: {
				deafault: {
					academicLevelDefault: 1,
					paperTypesDefault: 1,
					pagesDefault: 1
				}
			}
		});
	}

	if ($('[data-calc-mini]').length > 0) {
		new Calc({
			ID: '115',
			calculatorType: 'DefaultCalculator',
			format: 'html',
			environment: getEnvironment,
			options: {
				deafault: {
					deadlinesDefault: [97823],
					academicLevelDefault: 2,
					paperTypesDefault: 1,
					pagesDefault: 2
				}
			}
		});
	}
});

/* =============================
*  end NEW CALC INIT
================================ */

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '115',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);
